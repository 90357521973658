import React from "React";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import "../styles/footer.scss";

const Footer = () => {
	return (
		<footer>
			<Link to="https://www.unilever.com/" className="logo_container">
				<StaticImage
					src="../images/logo_footer.svg"
					alt="Dove"
					placeholder="transparent"
					className="logo"
					quality={100}
				/>
			</Link>
		</footer>
	);
};

export default Footer;
