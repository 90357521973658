import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import "../styles/header.scss";

const Header = () => {
	return (
		<header>
			<Link to="/" className="logo_container">
				<StaticImage
					src="../images/logo.png"
					alt="Dove"
					placeholder="transparent"
					className="logo"
					quality={100}
				/>
			</Link>
			<Link to="/rules">
				<span
					className={`button accent ${
						typeof window !== "undefined" &&
						window.location.href.split("/")[
							window.location.href.split("/").length - 1
						] === "rules"
							? " active"
							: ""
					}`}
				>
					Pravila nagradne igre
				</span>
			</Link>
		</header>
	);
};

export default Header;
