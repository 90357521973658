import React from "React";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import "../styles/hero.scss";

const Hero = () => {
	let isMobile = 0;
	if (typeof window !== "undefined") isMobile = window.innerWidth < 460;

	return (
		<section className="hero">
			<div className="container">
				<div className="text">
					<h1>Nagradna igra povodom dana očeva!</h1>
					<h3>1.3. - 31. 3. 2022.</h3>
					<p>
						Kupi 2 Dove MEN+CARE proizvoda ili 1 Dove MEN+CARE
						proizvod te pošalji SMS sadržaja DANOCEVA, broj računa,
						ime i prezime, adresa (ulica i kućni broj, poštanski
						broj i mjesto) na 60777 (2,40kn /SMS) ili se prijavi na
						ovoj stranici.
					</p>
					<Link to="rules">
						<span className="button" style={{ display: "none" }}>
							Provjeri dobitnike
						</span>
					</Link>
				</div>

				{isMobile ? (
					<div className="prizes">
						<div className="full">
							<div className="images">
								<StaticImage
									src="../images/prva_nagrada.png"
									alt="Dove"
									placeholder="transparent"
									className="img"
									quality={100}
								/>
							</div>
						</div>
						<div className="half">
							<div className="images">
								<StaticImage
									src="../images/druga_nagrada.png"
									alt="Dove"
									placeholder="transparent"
									className="img"
									quality={100}
								/>
							</div>
						</div>
						<div className="half">
							<div className="images">
								<StaticImage
									src="../images/treca_nagrada.png"
									alt="Dove"
									placeholder="transparent"
									className="img"
									quality={100}
								/>
							</div>
						</div>
					</div>
				) : (
					<StaticImage
						src="../images/sve_skupa.png"
						alt="Dove"
						placeholder="transparent"
						className="img desktop"
						quality={100}
					/>
				)}
			</div>
		</section>
	);
};

export default Hero;
